.work-header {
  font-family: "Roc Grotesk 500";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 70px;
  text-align: center;
  margin: 0;
  padding-top: 240px;
}
.work-des {
  font-family: "Roc Grotesk 500";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  margin-top: 10px;
}

// MacBook Pro 16
@media (max-width: 1728px) {
  .work-header {
    font-size: 35px;
    line-height: 58px;
    padding-top: 193px;
  }
  .work-des {
    margin-top: 3px;
  }
}

// MacBook Pro 14
@media (max-width: 1512px) {
  .work-header {
    font-size: 30px;
    line-height: 48px;
    padding-top: 160px;
  }
  .work-des {
    font-size: 17px;
    line-height: 25px;
    margin-top: 3px;
  }
}

// Desktop
@media (max-width: 1440px) {
  .work-header {
    padding-top: 160px;
  }
  .work-des {
    margin-top: 3px;
  }
}

// IPad Pro 12.9
@media (max-width: 1024px) {
  .work-header {
    font-size: 18px;
    line-height: 28px;
    padding-top: 140px;
  }
  .work-des {
    font-size: 16px;
    line-height: 24px;
    margin-top: 10px;
  }
}

// IPad Pro 11
@media (max-width: 834px) {
  .work-header {
    font-size: 20px;
    line-height: 34px;
  }
  .work-des {
    font-size: 17px;
    line-height: 25px;
  }
}

// Iphone 14 Pro Max
@media (max-width: 430px) {
}

// Iphone 14
@media (max-width: 390px) {
}

// Android Large
@media (max-width: 360px) {
}
