.presentations {
    background-color: black;
    color: white;
    .presentations-examples {
        .presentations-two-pictures {
            display: flex;
            justify-content: center;
            margin-bottom: 72px;
            .presentations-img {
                width: 620.91px;
                height: 350px;
            }

            .presentations-img-right {
                margin-left: 72px;
            }
        }
    }
}

// MacBook Pro 16
@media (max-width: 1728px) {
    .presentations {
        .presentations-examples {
            .presentations-two-pictures {
                .presentations-img {
                    width: 620.91px;
                    height: 350px;
                }
            }
        }
    }
}

// MacBook Pro 14
@media (max-width: 1512px) {
    .presentations {
        .presentations-examples {
            .presentations-two-pictures {
                margin-bottom: 62.9px;
                .presentations-img {
                    width: 542.55px;
                    height: 305.83px;
                }
                .presentations-img-right {
                    margin-left: 62.9px;
                }
            }
        }
    }
}

// Desktop
@media (max-width: 1440px) {
    .presentations {
        .presentations-examples {
            .presentations-two-pictures {
                margin-bottom: 65.53px;
                .presentations-img {
                    width: 565.15px;
                    height: 318.57px;
                }
                .presentations-img-right {
                    margin-left: 65.53px;
                }
            }
        }
    }
}

// MacBook Pro air
@media (max-width: 1280px) {
    .presentations {
        .presentations-examples {
            .presentations-two-pictures {
                margin-bottom: 58.3px;
                .presentations-img {
                    width: 502.85px;
                    height: 283.45px;
                }
                .presentations-img-right {
                    margin-left: 58.3px;
                }
            }
        }
    }
}

// IPad Pro 12.9
@media (max-width: 1100px) {
    .presentations {
        .presentations-examples {
            .presentations-two-pictures {
                display: block;
                text-align: center;
                margin-bottom: 66.53px;
                .presentations-img {
                    width: 659px;
                    height: 371.47px;
                }
                .presentations-img-right {
                    margin-left: 0;
                    margin-top: 66.53px;
                }
            }
        }
    }
}

// Iphone 14 Pro Max
@media (max-width: 600px) {
    .presentations {
        .presentations-examples {
            .presentations-two-pictures {
                margin-bottom: 52.77px;
                .presentations-img {
                    width: 302px;
                    height: 170.23px;
                }
                .presentations-img-right {
                    margin-top: 52.77px;
                }
            }
        }
    }
}