.header-button {
  button {
    width: auto;
    height: auto;

    color: #48d3a4;
    text-align: center;
    font-family: "Roc Grotesk 500";
    font-size: 20px;
    text-decoration-line: underline;
    background-color: transparent;
    border: 0;
    cursor: pointer;
  }
}

.work-button {
  text-align: center;
  margin-top: 130px;
  padding-bottom: 130px;
}

// MacBook Pro 16
@media (max-width: 1728px) {
  .header-button {
    button {
      border-radius: 9px;
      font-size: 18px;
    }
  }
}

// MacBook Pro 14
@media (max-width: 1512px) {
  .header-button {
    button {
      border-radius: 6.8888px;
      font-size: 16px;
    }
  }
}

// Desktop
@media (max-width: 1440px) {
  .header-button {
    button {
      font-size: 16px;
    }
  }
}

// Macbook Air
@media (max-width: 1440px) {
  .header-button {
    button {
      font-size: 16px;
    }
  }
}

// IPad Pro 12.9
@media (max-width: 1024px) {
  .header-button {
    button {
      border-radius: 7.4961px;
      font-size: 17px;
    }
  }
  .work-button {
    text-align: center;
    margin-top: 90px;
    padding-bottom: 90px;
  }
}

// IPad Pro 11
@media (max-width: 1024px) {
  .header-button {
    button {
      font-size: 16px;
    }
  }
}

// Iphone
@media (max-width: 430px) {
  .header-button {
    button {
      border-radius: 7.50346px;
      font-size: 15px;
    }
  }
}
