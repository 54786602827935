.goal {
  background: #20392d;
  position: relative;
  height: 650px;
  .goal-green {
    width: 50%;
    padding-left: 303px;
    padding-top: 130px;
    h2 {
      font-family: "Roc Grotesk 800";
      font-size: 53px;
      line-height: 1.59px;
      color: #ffcf79;
    }
    p {
      color: #ffcf79;
      font-family: "Roc Grotesk 300";
      font-size: 18px;
      line-height: 15px;
      margin-top: 70px;
    }
    .goal-button {
      margin-top: 118px;
    }
  }
  .goal-img {
    img {
      position: absolute;
      width: 50%;
      height: 100%;
      top: 0;
      right: 0;
      background-position: left top, right top;
    }
  }
}

// MacBook Pro 16
@media (max-width: 1728px) {
  .goal {
    height: 585px;
    .goal-green {
      padding-left: 271px;
      padding-top: 120px;
      h2 {
        font-size: 45px;
        line-height: 63px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        margin-top: 47.7px;
      }
      .goal-button {
        margin-top: 95.8px;
      }
    }
  }
}

// MacBook Pro 14
@media (max-width: 1512px) {
  .goal {
    height: 512.04px;
    .goal-green {
      padding-left: 253.66px;
      padding-top: 105px;
      h2 {
        font-size: 38px;
        line-height: 55px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        margin-top: 41.75px;
      }
      .goal-button {
        margin-top: 83.24px;
      }
    }
  }
}

// Desktop
@media (max-width: 1440px) {
  .goal {
    height: 488.09px;
    .goal-green {
      padding-left: 150px;
      padding-top: 100px;
      h2 {
        font-size: 36px;
        line-height: 52px;
      }
      p {
        font-size: 14px;
        line-height: 15px;
        margin-top: 39.63px;
      }
      .goal-button {
        margin-top: 80px;
      }
    }
  }
}

// MacBook Pro air
@media (max-width: 1280px) {
  .goal {
    height: 433.68px;
    .goal-green {
      padding-left: 217px;
      padding-top: 88px;
      h2 {
        font-size: 32px;
        line-height: 46.7px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        margin-top: 41.82px;
      }
      .goal-button {
        margin-top: 55.48px;
      }
    }
  }
}

// IPad Pro 12.9
@media (max-width: 1190px) {
  .goal {
    height: 415px;
    .goal-green {
      padding-left: 110px;
      padding-top: 50px;
      h2 {
        font-size: 32px;
        line-height: 70px;
        margin-bottom: 40px;
      }
      p {
        font-size: 15px;
        margin-top: 57px;
      }
      .goal-button {
        margin-top: 82.74px;
      }
    }
    .goal-img {
      img {
        width: 523px;
        height: 415px;
      }
    }
  }
}

// IPad Pro 11
@media (max-width: 970px) {
  .goal {
    .goal-green {
      width: 52%;
      height: 415px;
      padding-left: 88px;
      padding-top: 50px;
      h2 {
        font-size: 32px;
        line-height: 70px;
        margin-bottom: 40px;
      }
      p {
        font-size: 16px;
      }
      .goal-button {
        margin-top: 58.74px;
      }
    }
    .goal-img {
      img {
        width: 428px;
        height: 415px;
      }
    }
  }
}

// Iphone 14 Pro Max
@media (max-width: 855px) {
  .goal {
    height: 368px;
    .goal-green {
      width: 100%;
      height: 100%;
      padding-left: 0;
      padding-top: 108px;
      text-align: center;
      h2 {
        font-size: 25px;
        line-height: 36px;
        margin-top: 0;
      }
      p {
        font-size: 15px;
        line-height: 22px;
        margin-top: 32px;
      }
      .goal-button {
        margin-top: 32px;
      }
    }
    .goal-img {
      display: none;
    }
  }
}
